import React, { Component } from 'react';

import {Header, Button} from 'semantic-ui-react'

class Failed extends Component{


    back = (e) => {
        e.preventDefault();
        this.props.setStep(1)
        window.location.reload();
    }

    
    render(){
        return(
            <div className="errorForm">
            <Header as="h1" color="red" textAlign="center" >Registration Failed with following message:</Header>
            <h2>{this.props.errorMessage}</h2>
            <Button basic primary onClick={this.back}>Try again</Button>
        </div>
        )
    }
}

export default Failed;