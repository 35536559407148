
import React, { Component } from 'react';
import UserDetail from './UserDetails';
import RequestOTP from './RequestOTP'
import ChannelInfo from './ChannelInfo';
import Failed from './Failed';
import Success from './Succes';
import Waiting from './Waiting';
import Start from './Start';
import SubmitOTP from './SubmitOTP';
import api from '../api'
import GroupInfo from './GroupInfo'
import TabComponent from './TabComponent'


class MainForm extends Component {
    state = {
        step: 1,
        accountGuid: '',
        phoneNumber: '',
        address: '',
        specification: '',
        email: '',
        businessCategory: '',
        website: '',
        website2: '',
        errorMessage: '',
        certificate: '',
        otpCode: '',
        about: '',
        GroupName: ''
    }

    nextStep = () => {
        const { step } = this.state
        this.setState({
            step: step + 1
        })
    }

    setStep = (step) => {
        this.setState({
            step
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step: step - 1
        })
    }

    logErrorMessage = (message) => {
        console.log(message)
        this.setState({
            errorMessage: message
        })
    }

    handleChange = input => event => {
        this.setState({ [input]: event.target.value })
    }



    componentDidMount() {

        //this.handleStatus()


    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async handleStatus() {
        await this.sleep(3000)

        const account = window.getCmAccount()

        if (account) {

            const guid = account.AccountGuid;

            var status = await api.getStatus(guid);

            if (status) {
                console.log(status)
                switch (status) {
                    case 'Unconfigured':
                        this.setStep(2)
                        break;
                    case 'CreatedSettings':
                        this.setStep(8)
                        break;
                    case 'WaWebRunning':
                        this.setStep(3)
                        break;
                    case 'RequestedOtp':
                        this.setStep(7)
                        break;
                    case 'VerifiedOtp':
                        this.setStep(4);
                
                        Promise.all([
                            api.getProfilePicture(guid),
                            api.getAbout(guid),
                            api.getBusinessProfile(guid)
                        ]).then(async (promiseDatas) => {
                            // Loaded a lot.
            
                            let about = promiseDatas[1];
                            let file = promiseDatas[0];
                            let details = promiseDatas[2];

                            this.setState({
                                about: about.text,
                                address: details.address,
                                specification: details.description,
                                email: details.email,
                                businessCategory: details.vertical,
                                website: details.websites[0],
                                website2: details.websites[1],
                                hasAdditionalWebsite: details.websites[1] != null,
                                file: file,
                                imagePreviewUrl: await this.readFileAsync(file)
                            });
                        })
                        .catch((error) => {
                            this.props.onFail(error.message);
                            this.props.setStep(5)
                        });
                        break;
                }
            }
            else {
                const errorMessage = 'No status found. Please contact support'
                this.logErrorMessage(errorMessage);
                this.setStep(5)
            }
        }

        if (account === null) {
            const errorMessage = 'Please log in with the account of the customer'
            this.props.onFail(errorMessage);
            this.props.setStep(5)
        }
    }

    readFileAsync(file) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
      
          reader.onload = () => {
            resolve(reader.result);
          };
      
          reader.onerror = reject;
      
          reader.readAsDataURL(file);
        });
    }


    render() {
        const { step } = this.state;
        const { accountGuid, certificate, phoneNumber, address, specification, email, website, website2, businessCategory, otpCode, about, hasAdditionalWebsite, imagePreviewUrl, GroupName } = this.state;
        const values = { about, accountGuid, certificate, phoneNumber, address, specification, email, website, website2, businessCategory, otpCode, hasAdditionalWebsite, imagePreviewUrl, GroupName };

        switch (step) {
            case 1:
                return <TabComponent
                   
                />
            case 2:
                return <UserDetail
                    handleStatus={this.handleStatus}
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    setStep={this.setStep}
                    onFail={this.logErrorMessage}
                    values={values}
                />
            case 3:
                return <RequestOTP
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    setStep={this.setStep}
                    onFail={this.logErrorMessage}
                    values={values}
                />
            case 4:
                return <ChannelInfo
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    setStep={this.setStep}
                    onFail={this.logErrorMessage}
                    values={values}
                />
            case 5:
                return <Failed
                    errorMessage={this.state.errorMessage}
                    setStep={this.setStep}
                />
            case 6:
                return <Success
                    setStep={this.setStep}
                />
            case 7:
                return <SubmitOTP
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    setStep={this.setStep}
                    onFail={this.logErrorMessage}
                    values={values}
                />
            case 8:
                return <Waiting
                    setStep={this.setStep}
                />

            default:
                return <UserDetail
                    nextStep={this.nextStep}
                    handleChange={this.handleChange}
                    setStep={this.setStep}
                    onFail={this.logErrorMessage}
                    values={values}
                />
        }
    }
}

export default MainForm;