
import React, { Component } from "react";
import { Form, Icon, Button, Container, TextArea } from "semantic-ui-react";

import api from "../api";

class ChannelInfo extends Component {
  render() {
    return (
      <Container style={{ width: "70%" }}>
        <Form className="summary-form">
          <h1 className="summary-header">WhatsApp Channel Information</h1>
          <Form.Field>
            <label className="summary-label">Description</label>
            <TextArea
              className="summary-field"
              placeholder="CM.com provides a communication platform (cPaaS) in the area of Messaging, Voice, Payments and Authentication."
            />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input type="text" placeholder="example@hotmail.com" />
          </Form.Field>
          <Form.Field>
            <label>Business address</label>
            <input type="text" placeholder="Konijnenberg 30, 4825 BD Breda" />
          </Form.Field>
          <Form.Field>
            <label className="summary-label">About</label>
            <TextArea
              className="summary-field"
              placeholder="CM.com provides a communication platform (cPaaS) in the area of Messaging, Voice, Payments and Authentication."
            />
          </Form.Field>
          <Form.Field>
            <label>Business category</label>
            <input type="text" placeholder="Telecom" />
          </Form.Field>
          <Form.Field>
            <label>Website</label>
            <div class="ui labeled input">
              <div class="ui label">https://</div>
              <input type="text" placeholder="www.cm.com" />
            </div>
          </Form.Field>
          <Button
            size="medium"
            icon
            labelPosition="right"
            onClick={this.addWebsiteInput}
          >
            Another
            <Icon size="large" name="add circle" />
          </Button>
          <Form.Field>
            <label>Website 2</label>
            <div class="ui labeled input">
              <div class="ui label">https://</div>
              <input type="text" placeholder="www.cm.com" />
            </div>
          </Form.Field>
          <Button
            icon
            labelPosition="right"
            class="addwebsitebutton"
            onClick={this.removeWebsiteInput}
          >
            Cancel
            <Icon size="large" name="cancel" />
          </Button>
          <Button primary>Send </Button>
        </Form>
      </Container>
    );
  }
}

export default ChannelInfo;