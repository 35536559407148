import React, { Component } from 'react';

import {Header} from 'semantic-ui-react'

class Waiting extends Component{
    
    render(){
        return(
            <div className="successForm">
                <Header as="h1" color="green" textAlign="center">
                    Configuration for this account has been made.            
                </Header>
                <Header as="h3" color="black" textAlign="center">
                    Docker is currently in creating state, this might take up to 5 minutes.
                    Please refresh once in a while, or contact text-team if it takes longer than 10 minutes.
                </Header>
            </div>
        )
    }
}

export default Waiting;