import React from "react";
import { Label, Menu, Tab } from "semantic-ui-react";

import GroupInfo from "./GroupInfo";

import ChannelInfo from "./ChannelInfo";

const panes = [
  {
    menuItem: { key: "Groups", icon: "users", content: "Groups" },
    render: () => (
      <Tab.Pane>
        <GroupInfo />
      </Tab.Pane>
    )
  },
  {
    menuItem: { key: "Profile", icon: "user", content: "Profile" },
    render: () => (
      <Tab.Pane>
        <ChannelInfo />
      </Tab.Pane>
    )
  }
];

const TabExampleCustomMenuItem = () => <Tab panes={panes} />;

export default TabExampleCustomMenuItem;
