import React, {
  Component
} from "react";

import {
  Form,
  Image,
  Button,
  Container,
  Header,
  List,
  Grid,
  TextArea,
  Modal,
  Label,
  Loader,
  Popup,
  Icon
} from "semantic-ui-react";

import api from '../api'

class GroupInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      /* Application */
      isLoading: false,
      isModalLoading: false,
      isStartModalOpen: true,

      /* WhatsApp Group */
      groups: [],
      groupParticipants: [],
      groupAdmins: [],
      subject: "",
      groupName: "",
      inviteLink: "",
      groupIconUrl: "",

      /* Message */
      message: ""
    };

    this.CreateNewGroup = this.CreateNewGroup.bind(this)

  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- ON PAGE LOAD -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */

  async componentDidMount() {

    this.setState({
      isLoading: true,
    })

    await this.sleep(3000)

    try {
      var account = window.getCmAccount()
    } catch (err) {
      console.log("ERROR: getCmAccount() in componentDidMount")
    }

    if (account) {

      const guid = account.ID;

      try {
        const response = await api.getAllGroups(guid);
        if (response != null) {
          console.log("UPDATE: Found whatsapp groups")
          this.setState({
            groups: response.groups
          })
        } else {
          console.log("UPDATE: No whatsapp groups found")
          this.setState({
            groups: [{
              id: 'no groups found'
            }]
          })
        }
      } catch (err) {
        console.log('ERROR: GetAllGroups() threw an error')
      }
    } else {
      this.setState({
        groups: [{
          id: 'no groups found'
        }]
      })
    }
    this.setState({
      isLoading: false
    })
  }

  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- HANDLE CHANGE -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */

  handleChange = input => event => {
    this.setState({
      [input]: event.target.value
    })
  }

  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- GROUP FUNCTIONS (API) -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */

  async SendMessage(selectedGroupId, inputMessage) {

    // try {
    //   var account = window.getCmAccount()
    // } catch (err) {
    //   console.log("ERROR: getCmAccount() in SendMessage()")
    // }

    try {
      // if (account) {
      //   const guid = account.ID;

        await api.sendMessage(selectedGroupId, inputMessage)
      //}
    } catch (err) {
      console.log("ERROR: SendMessage() threw an error")
      console.log(err.message)
    }
  }

  async CreateNewGroup() {

    try {
      var account = window.getCmAccount()
    } catch (err) {
      console.log("ERROR: getCmAccount() in CreateNewGroup()")
    }

    try {
      if (account) {
        const guid = account.ID;

        console.log("UPDATE: Creating group: " + this.state.subject)

        const groupName = {
          subject: this.state.subject
        }

        await api.createGroup(guid, groupName)

        this.componentDidMount();
      }
    } catch (err) {
      console.log("ERROR: CreateNewGroup() threw an error")
    }

    this.setState({
      subject: ''
    })
  }

  async updateGroupName(selectedGroupId) {

    this.setState({
      isModalLoading: true
    })

    try {
      var account = window.getCmAccount()
    } catch (err) {
      console.log("ERROR: getCmAccount() in updateGroupName()")
    }

    const guid = account.ID;

    const groupName = {
      subject: this.state.groupName
    }

    try {
      await api.updateGroup(guid, selectedGroupId, groupName)
      this.setState({
        isModalLoading: false
      })
    } catch (err) {
      this.setState({isModalLoading: false})
      console.log("ERROR: UpdateGroup API call threw an error")
    }
  }

  async leaveGroupViaModalButton(selectedGroupId) {

    try {
      var account = await window.getCmAccount()
    } catch (err) {
      console.log("ERROR: getCmAccount() in leaveGroupViaModalButton()")
    }

    try {
      if (account) {

        const guid = account.ID;

        await api.leaveGroup(guid, selectedGroupId)
        this.componentDidMount()
      }
    } catch (err) {
      this.setState({isModalLoading: false})
      console.log("ERROR: leaveGroupViaModalButton() threw an error")
    }
  }

  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- PARTICIPANT/ADMIN/LINK FUNCTIONS (API) -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */

  async addAdmin(selectedGroupId, selectedWhatsAppID) {

    this.setState({
      isModalLoading: true
    })

    try {
      var account = window.getCmAccount()
    } catch (err) {
      console.log("ERROR: getCmAccount() in addAdmin()")
    }

    try {
      if (account) {
        const guid = account.ID;
        await api.addGroupAdmins(guid, selectedGroupId, selectedWhatsAppID)
        var admins = await api.getSingleGroup(guid, selectedGroupId) //TODO PROBLEM
        this.setState({
          groupAdmins: admins.groups[0].admins,
          isModalLoading: false
        })
      }
    } catch (err) {
      this.setState({isModalLoading: false})
      console.log("ERROR: addAdmin() threw an error")
    }
  }

  async deleteAdmin(selectedGroupId, selectedWhatsAppID) {

    this.setState({
      isModalLoading: true
    })

    try {
      var account = window.getCmAccount()
    } catch (err) {
      console.log("ERROR: getCmAccount() in deleteAdmin()")
    }

    try {
      if (account) {
        const guid = account.ID;
        await api.deleteGroupAdmins(guid, selectedGroupId, selectedWhatsAppID)
        var admins = await api.getSingleGroup(guid, selectedGroupId) //TODO PROBLEM
        this.setState({
          groupAdmins: admins.groups[0].admins,
          isModalLoading: false
        })
      }
    } catch (err) {
      this.setState({isModalLoading: false})
      console.log("ERROR: deleteGroupParticipants() threw an error")
    }
  }

  async deleteParticipant(selectedGroupId, selectedWhatsAppID) {

    this.setState({
      isModalLoading: true
    })

    try {
      var account = window.getCmAccount()
    } catch (err) {
      console.log("ERROR: getCmAccount() in deleteParticipant()")
    }

    try {
      if (account) {
        const guid = account.ID;
        await api.deleteGroupParticipants(guid, selectedGroupId, selectedWhatsAppID)
        var participants = await api.getSingleGroup(guid, selectedGroupId)
        this.setState({
          groupParticipants: participants.groups[0].participants,
          isModalLoading: false
        })
      }
    } catch (err) {
      this.setState({isModalLoading: false})
      console.log("ERROR: deleteGroupParticipants() threw an error")
    }
  }

  async UpdateGroupLink(selectedGroupId) {

    try {
      var account = window.getCmAccount()
    } catch (err) {
      this.setState({isModalLoading: false})
      console.log("ERROR: getCmAccount() in UpdateGroupLink()")
    }

    try {
      if (account) {
        const guid = account.ID;
        await api.deleteInviteLink(guid, selectedGroupId)
        var inviteLink = await api.getInviteLink(guid, selectedGroupId)
        this.setState({
          inviteLink: inviteLink.groups[0].link
        })
      }
    } catch (err) {
      this.setState({isModalLoading: false})
      console.log("ERROR: UpdateInviteLink() threw an error")
    }
  }

  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- MODAL STATES -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */

  openGroupModal = group => {

    this.setState({
      isModalLoading: true,
      groupModalIsOpen: true
    });

    try {
      var account = window.getCmAccount()
    } catch (err) {
      console.log("ERROR: getCmAccount() in openGroupModal()")
    }

    if (account) {
      const guid = account.ID;

      const groupId = group.id

      Promise.all([
          api.getGroupIconUrl(guid, groupId),
          api.getInviteLink(guid, groupId),
          api.getSingleGroup(guid, groupId)
        ])
        .then(async promiseDatas => {
          // Loaded a lot.

          let groupIconUrl = promiseDatas[0];
          let inviteLink = promiseDatas[1];
          let group = promiseDatas[2];

          this.setState({
            groupIconUrl: groupIconUrl.groups[0].icon.link,
            inviteLink: inviteLink.groups[0].link,
            groupName: group.groups[0].subject,
            groupParticipants: group.groups[0].participants,
            groupAdmins: group.groups[0].admins,
            selectedGroupId: groupId,
            isModalLoading: false
          });
        })
        .catch(error => {
          this.setState({isLoading: false, isModalLoading: false})
          console.log(error);
        });
    }
  };

  refreshModal = selectedGroupId => {

    try {
      var account = window.getCmAccount()
      this.setState({
        isModalLoading: true
      })
    } catch (err) {
      console.log("ERROR: getCmAccount() in openGroupModal()")
    }

    if (account) {
      const guid = account.ID;

      const groupId = selectedGroupId

      Promise.all([
          api.getGroupIconUrl(guid, groupId),
          api.getInviteLink(guid, groupId),
          api.getSingleGroup(guid, groupId)
        ])
        .then(async promiseDatas => {
          // Loaded a lot.

          let groupIconUrl = promiseDatas[0];
          let inviteLink = promiseDatas[1];
          let group = promiseDatas[2];

          this.setState({
            groupIconUrl: groupIconUrl.groups[0].icon.link,
            inviteLink: inviteLink.groups[0].link,
            groupName: group.groups[0].subject,
            groupParticipants: group.groups[0].participants,
            groupAdmins: group.groups[0].admins,
            selectedGroupId: groupId,
            isModalLoading: false
          });
        })
        .catch(error => {
          this.setState({
            isLoading: false,
            isModalLoading: false
          })
          console.log(error);
        });
    }
  }

  closeGroupModal = () => {
    this.setState({
      groupModalIsOpen: false
    });
  };

  closeStartModal = () => {
    this.setState({
      isStartModalOpen: false
    })
  }

  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- RENDER COMPONENTS -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */
  render() {

    const state = this.state;
    const {isLoading} = this.state;
    const {isModalLoading} = this.state;


  /* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- INLINE STYLES -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */
    const groupLoaderStyle = {
      'position': 'absolute',
      'top': '69%',
      'left': '15%'
    }

    const modalLoaderStyle = {
      'position': 'absolute',
      'top': '52%',
      'left': '63%'
    }

    const groupParticipantStyle = {
     'display': 'inline'
    }

    const groupImageStyle = {
      'width': '300px',
      'height': '300px',
      'borderStyle': 'solid',
      'borderWidth': '1px'
    }

    const ribbonStyle = {
      'left': '-5%'
    }

    const versionLabelStyle = {
      'margin': '5px'
    }

    const chatBubbleStyle = {
      'size': 'big',
      'position': 'absolute',
      'left' : '2%',
      'top': '91%'
    }

    return (
  
    <Grid>

      <Modal open={this.state.isStartModalOpen} onClose={this.state.closeStartModal} basic size='small' style={{ position: 'relative'}}>
            <Header icon='exclamation triangle' content='INTERNAL USE/TESTING ONLY'/>
              <Modal.Content>
                <h4>This front-end application is a PROOF OF CONCEPT, and not intended for use by customers.</h4>
                <h4>Its only purpose is to be used for testing and/or showcase the potential of the accompanying back-end.</h4>
              </Modal.Content>
              <Modal.Actions>
                <Button basic color='red' inverted onClick={this.closeStartModal}>
                  <Icon name='remove'/> Acknowledge and close
                </Button>
              </Modal.Actions>
            </Modal>

      <Grid.Row>
        <Grid.Column width={8}>
          <h1 className="summary-header">Current Groups</h1>
          <List>
            {isLoading && <Loader style={groupLoaderStyle} active>Loading Groups...</Loader>}
            {!isLoading &&
            <List.Content floated="right">
              {this.state.groups.map((group, i) => (
              <List.Item key="{i}">
                <span>{`Group-ID: ${group.id} `}
                  <Icon name="folder open" onClick={()=> this.openGroupModal(group)}/>
                </span>
              </List.Item>
              ))}
            </List.Content>
            }
          </List>
          <Modal dimmer='inverted' open={this.state.groupModalIsOpen} onClose={this.state.closeGroupModal} style={{ position: 'relative' }}>
            <Modal.Header>
              <Label style={ribbonStyle} color='green' ribbon="left" size="huge">
                {isModalLoading && <p>Loading...</p>}                
                {!isModalLoading && 
                <p>
                  Group: {this.state.selectedGroupId}
                </p>
                }
              </Label>
            </Modal.Header>
            <Modal.Content image>
              {isModalLoading && <Image style={groupImageStyle}><Loader active>Loading image...</Loader></Image>}
              {!isModalLoading && <Image style={groupImageStyle} bordered src={state.groupIconUrl} />}
              <Modal.Description className="modal-description">
                {isModalLoading && <Loader style={modalLoaderStyle} active>Loading group info...</Loader>}
                {!isModalLoading &&
                <Form>
                  <Form.Field>
                    <label className="summary-label">Group name</label>
                    <input className='modalInput' type="text" placeholder="group name" onChange={this.handleChange("groupName")} defaultValue={this.state.groupName} />
                    <Button color="black" onClick={(event)=> {
                      this.updateGroupName(this.state.selectedGroupId)}}>
                      Update{""}
                    </Button>
                  </Form.Field>
                  <Form.Field>
                    <label className="summary-label">Invite Link</label>
                    <i className="linkify icon"></i>
                    <a onChange={this.handleChange("inviteLink")} href={this.state.inviteLink}>{this.state.inviteLink}</a>
                    <Icon className="refresh" size="large" onClick={(event)=> {this.UpdateGroupLink(this.state.selectedGroupId)}}/>
                  </Form.Field>
                  <Form.Field>
                    <label className="summary-label">Current participants</label>
                    <List>
                      {this.state.groupParticipants.map((groupParticipant) => (
                      <List.Item style={groupParticipantStyle} key="{i}">
                        {`Whatsapp-ID: ${groupParticipant} `}
                        <Icon name="close" onClick={(event)=> {this.deleteParticipant(this.state.selectedGroupId, groupParticipant)}}/>
                          <Icon name="key" onClick={(event)=> {this.addAdmin(this.state.selectedGroupId, groupParticipant)}}/>
                      </List.Item>
                      ))}
                    </List>
                  </Form.Field>
                  <Form.Field>
                    <label className="summmary-label">Current admins</label>
                    <List>
                      {this.state.groupAdmins.map((groupAdmin) => (
                      <List.Item key="{i}">
                        {`Whatsapp-ID: ${groupAdmin} `}
                        <Icon name="close" onClick={(event)=> {this.deleteAdmin(this.state.selectedGroupId, groupAdmin)}}/>
                      </List.Item>
                      ))}
                    </List>
                  </Form.Field>
                </Form>
                }
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
            <Popup 
                  on='click'
                  pinned
                  trigger={<Icon style={chatBubbleStyle} name="talk" size="big"/>}>
                    <Form>
                      {/* <Form.Field>
                        <label className="summary-label">Product Token</label>
                        <input type="text" placeholder={this.state.productToken}
                        onChange={this.handleChange("productToken")} defaultValue={this.state.productToken}/>        
                      </Form.Field>
                      <Form.Field>
                        <label className="summary-label">Sender</label>
                        <input type="text" placeholder={this.state.sender}
                        onChange={this.handleChange("productToken")} defaultValue={this.state.sender}/>        
                      </Form.Field> */}
                      <Form.Field>
                        <label className="summary-label">Message</label>
                        <input type="text" placeholder="Message"
                        onChange={this.handleChange("message")}/>        
                      </Form.Field>
                          <Button color="black" onClick={(event)=> {
                              this.SendMessage(this.state.selectedGroupId, this.state.message)}}>
                              Send{""}
                          </Button>
                    </Form>
                  </Popup>
              <Button.Group>
              <Button color="grey" onClick={(event)=> {this.refreshModal(this.state.selectedGroupId);}}>
                Refresh{" "}
                </Button>
                <Button.Or/>
                <Button color="red" onClick={(event)=> {this.leaveGroupViaModalButton(this.state.selectedGroupId); this.closeGroupModal()}}>
                Leave Group{" "}
                </Button>
                <Button.Or/>
                <Button color="black" onClick={this.closeGroupModal}>
                Close{" "}
                </Button>
              </Button.Group>
            </Modal.Actions>
          </Modal>
        </Grid.Column>
        <Grid.Column width={8}>
          <Form>
            <h1 className="summary-header">Create new group</h1>
            <Form.Field>
              <label className="summary-label">WhatsApp Group Name</label>
              <input className="summary-field" placeholder="Groupname" onChange={this.handleChange("subject")}/>
            </Form.Field>
            <Button color="green" onClick={(event)=> {this.CreateNewGroup(); this.componentDidMount()}}>
              Create{" "}
            </Button>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Label style={versionLabelStyle} basic color='green'><Icon name="whatsapp" color='black'>
        </Icon>Version 4.2 - Internal use only</Label>
    </Grid>
    );
  }
}

export default GroupInfo;