const axios = require('axios');

const Config = require('./Config')

const api = {

    getStatus(accountGuid) {
        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/registration`, {
                withCredentials: true
            })
            .then(resp => {
                const status = resp.data.status
                return status;
            })
            .catch(err => console.error(err));
    },

    addCustomerSettings(accountGuid, customerSettings) {
        return axios.post(`${Config.WhatsAppConfigApiUrl}${accountGuid}/registration`, customerSettings, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    requestOTP(accountGuid, otpRequest) {

        return axios.post(`${Config.WhatsAppConfigApiUrl}${accountGuid}/registration/requestOTP`, otpRequest, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    submitOTP(accountGuid, otpCode) {

        return axios.post(`${Config.WhatsAppConfigApiUrl}${accountGuid}/registration/submitOtp`, otpCode, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    addBusinessProfile(accountGuid, businessProfile) {

        return axios.put(`${Config.WhatsAppConfigApiUrl}${accountGuid}/business-profile/details`, businessProfile, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    addProfilePicture(accountGuid, profilePicture) {
        const config = {
            withCredentials: true,
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        const formData = new FormData()
        formData.append('file', profilePicture)

        return axios.put(`${Config.WhatsAppConfigApiUrl}${accountGuid}/business-profile/photo`, formData, config)
            .then(response => response.data)
    },

    addAboutSection(accountGuid, about) {
        return axios.put(`${Config.WhatsAppConfigApiUrl}${accountGuid}/business-profile/about`, about, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    getProfilePicture(accountGuid) {
        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/business-profile/photo`, {
                withCredentials: true,
                responseType: 'blob'
            })
            .then(resp => {
                const data = resp.data;
                return data;
            })
            .catch(err => console.error(err));
    },
    getAbout(accountGuid) {
        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/business-profile/about`, {
                withCredentials: true
            })
            .then(resp => {
                const status = resp.data;
                return status;
            })
            .catch(err => console.error(err));
    },

    getBusinessProfile(accountGuid) {
        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/business-profile/details`, {
                withCredentials: true
            })
            .then(resp => {
                const status = resp.data;
                return status;
            })
            .catch(err => console.error(err));
    },

    //Whatsapp Group Calls ---------------------------------------------------------------------------------------------------

    sendMessage(groupId, text) {
    var messages = {
        "messages": {
            "authentication": {
                "productToken": 'PLACEHOLDER'
            },
            "msg": [
                {
                    "RichContent": {
                        "conversation": [
                            {
                                "text": {text}
                            }
                        ]
                    },
                    "allowedChannels": [
                        "WhatsApp"
                    ],
                    "body": {
                        "content": {text}
                    },
                    "from": 'PLACEHOLDER',
                    "to": [
                        {
                            "number": {groupId}
                        }
                    ]
                }
            ]
        }
    }
        return axios.post(`${Config.GatewayMessageUrl}`, messages)
        .then(response => response.data)

    },

    createGroup(accountGuid, subject) {
        return axios.post(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups`, subject, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    getAllGroups(accountGuid) {
        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups`, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    getSingleGroup(accountGuid, groupId) {
        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}`, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    updateGroup(accountGuid, groupId, subject) {
        return axios.put(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}`, subject, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    leaveGroup(accountGuid, groupId) {
        return axios.post(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/leave`, {}, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    getInviteLink(accountGuid, groupId) {

        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/invite-link`, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    deleteInviteLink(accountGuid, groupId) {
        return axios.delete(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/invite-link`, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    deleteGroupParticipants(accountGuid, groupId, whatsappId) {
        const wa_ids = {
            wa_ids: [whatsappId]
        }
        return axios.delete(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/participants`, {
                withCredentials: true,
                data: wa_ids
            })
            .then(response => response.data)
    },

    addGroupAdmins(accountGuid, groupId, whatsappId) {
        const wa_ids = {
            wa_ids: [whatsappId]
        }
        return axios.post(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/admins`, wa_ids, {
                withCredentials: true
            })
            .then(response => response.data)
    },

    deleteGroupAdmins(accountGuid, groupId, whatsappId) {
        const wa_ids = {
            wa_ids: [whatsappId]
        }
        return axios.delete(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/admins`, {
                withCredentials: true,
                data: wa_ids
            })
            .then(response => response.data)
    },

    getGroupIcon(accountGuid, groupId) {
        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/icon`, {
                withCredentials: true,
                responseType: 'blob'
            })
            .then(resp => {
                const data = resp.data;
                return data;
            })
            .catch(err => console.error(err));
    },

    getGroupIconUrl(accountGuid, groupId) {
        return axios.get(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/icon-url`, {
                withCredentials: true
            })
            .then(response => response.data)
            .catch(err => {
                console.log("No image found")
                return {
                    "groups": [{
                        "icon": {
                            "link": "src/img/no-image.jpg"
                        }
                    }]
                }
            })
    },

    deleteGroupIcon(accountGuid, groupId) {
        return axios.delete(`${Config.WhatsAppConfigApiUrl}${accountGuid}/groups/${groupId}/icon`, {
                withCredentials: true
            })
            .then(response => response.data)
    }
};
module.exports = api;
