import React, { Component } from 'react';

import { Form,  Header, Radio, Button, Container, Modal } from 'semantic-ui-react';

import api from '../api'


class submitOTP extends Component {

    

    submitOTP = (e) => {

        const { values } = this.props;

        e.preventDefault()

        const otpCode = {
            code: values.otpCode
        }

        const account =  window.getCmAccount()

        if(account){

            const guid = account.AccountGuid;

        api.submitOTP(guid, otpCode)
        .then(() => {
            this.props.setStep(4)
        })
        .catch((error) => {
            this.props.onFail(error.message);
            this.props.setStep(5)
        })
    }
    }

    skip= (e) => {
        this.props.setStep(4)
      }


    handleChange = (e, { value }) => this.setState({ value })

    render() {
      
        const { values } = this.props;
        return (
            <div className="confirmation">
                <Header as="h1" color="black" textAlign="center" >Submit OTP</Header>
                <Container>
                    
                </Container>
                <Form className="summary-form"  >
                <Form.Field>
                         <label>OTP Code</label>
                        <input
                              type='text'
                              placeholder='unique code'
                              onChange={this.props.handleChange('otpCode')}
                              defaultValue={values.otpCode}
                        />
                    </Form.Field>
                    <Button primary onClick={this.submitOTP}>Submit</Button>
                    <Button primary  onClick={this.skip}>Skip </Button>
                    </Form>
            </div>
        );
    }
}

export default submitOTP;