import React, { Component } from 'react';

import {Header, Button} from 'semantic-ui-react'

class Success extends Component{


    back = (e) => {
        e.preventDefault();
        this.props.setStep(1)
    }

    
    render(){
        return(
            <div className="successForm">
            <Header as="h1" color="green" textAlign="center" >Registration Succesfull</Header>
            <Button basic primary onClick={this.back}>Add another account.</Button>
        </div>
        )
    }
}

export default Success;