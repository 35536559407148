import React, { Component } from 'react';

import { Form,  Header, Radio, Button, Container, Modal } from 'semantic-ui-react';

import api from '../api'


class requestOTP extends Component {

    state = {
        value: 'sms'
    }



    componentDidMount() {
        const { startOpen } = this.props;
    
        if (startOpen) {
          this.handleOpen();
        }
      }

      handleOpen = () => this.setState({ modalOpen: true });




    requestOTP = (e) => {
        
        const { values } = this.props;


      e.preventDefault()

      

      const account =  window.getCmAccount()

      if(account){

        const guid = account.AccountGuid;

        const otpRequest = {
            otpMethod: this.state.value
        }
  
        api.requestOTP(guid, otpRequest) 
        .then(() => {
           console.log('Succesfully requested an OTP')
          
        })
        .catch((error) => {
            this.props.onFail(error.message);
            this.props.setStep(5)
        })
      }

    if(account === null){
        const errorMessage = 'Please log in with the account of the customer'
        this.props.onFail(errorMessage);
        this.props.setStep(5)
    }



    }

    submitOTP = (e) => {

        const { values } = this.props;

        e.preventDefault()

        const otpCode = {
            code: values.otpCode
        }

        const account =  window.getCmAccount()

        if(account){

            const guid = account.AccountGuid;

        api.submitOTP(guid, otpCode)
        .then(() => {
            this.props.setStep(4)
        })
        .catch((error) => {
            this.props.onFail(error.message);
            this.props.setStep(5)
        })
    }
    }

    skip= (e) => {
        this.props.setStep(4)
      }


    handleChange = (e, { value }) => this.setState({ value })

    render() {
      
        const { values } = this.props;
        return (
            <div className="confirmation">
                <Header as="h1" color="green" textAlign="center" >Request your OTP</Header>
                <Container>
                    
                </Container>
                <Form className="summary-form"  >
                    <Form.Field>
                        <Radio
                            label='Send me a text message'
                            name='radioGroup'
                            value='sms'
                            checked={this.state.value === 'sms'}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='Call me'
                            name='radioGroup'
                            value='voice'
                            checked={this.state.value === 'voice'}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    
                <Modal
        trigger={<Button primary onClick={this.requestOTP} >Request OTP</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        basic
        size='small'
      >
      <Header icon='whatsapp' content='Submit OTP code' />
        <Modal.Content>
          <h3>Please submit the Code you received.</h3>
        </Modal.Content>
        <Modal.Actions>
            <Container>
            <Form  >
                    <Form.Field>
                        <input
                            type='text'
                            placeholder='unique code'
                            onChange={this.props.handleChange('otpCode')}
                            defaultValue={values.otpCode}
                        />
                    </Form.Field>
                    </Form>  
            </Container>
                    <Button primary onClick={this.submitOTP}>Submit</Button>
        </Modal.Actions>
      </Modal>
      <Button primary  onClick={this.skip}>Skip </Button>
                </Form>
            </div>
        );
    }
}

export default requestOTP;