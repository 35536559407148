import React, { Component } from 'react';
import { Form, Button, Container } from 'semantic-ui-react';


import api from '../api'

class UserDetails extends Component{

    saveAndContinue = (e) => {
        const { values } = this.props;

        const userSettings = {
          phonenumber: values.phoneNumber,
          certificate: values.certificate
        }
        
        const account = window.getCmAccount()

        if(account){

            const guid = account.AccountGuid;

            api.addCustomerSettings(guid, userSettings)
            .then(() => {
                this.props.setStep(8)
            })
            .catch((error) => {
                this.props.onFail(error.message);
                this.props.setStep(5)
            })
            e.preventDefault()
            this.props.nextStep()
        }

      const errorMessage = 'Please log in with the account of the customer'
      this.props.onFail(errorMessage);
      this.props.setStep(5)

    }

    skip= (e) => {
      this.props.setStep(3)
    }

    render(){
        const { values } = this.props;
        return(
            <Container style={{width: "70%"}} >           
            <Form  className="summary-form" >
                <h1 className="summary-header">Company information</h1>
                <Form.Field>
                    <label>Phone number</label>
                    <input
                    type='text'
                    placeholder='phone number including country code'
                    onChange={this.props.handleChange('phoneNumber')}
                    defaultValue={values.phoneNumber}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Certificate</label>
                    <input
                    type='text'
                    placeholder='Certificate'
                    onChange={this.props.handleChange('certificate')}
                    defaultValue={values.certificate}
                    />
                </Form.Field>
                <Button primary  onClick={this.saveAndContinue}>Send And Continue </Button>
                <Button primary  onClick={this.skip}>Skip </Button>
            </Form>
            </Container>
        )
    }
}

export default UserDetails;