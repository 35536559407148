import React, { Component } from 'react';
import './App.css';
import MainForm from './components/MainForm';
import { Container } from 'semantic-ui-react';


class App extends Component {

  render() {
    return( 
      <React.Fragment>
      <Container >
        <MainForm />
      </Container>
        </React.Fragment>
      )
  }
}

export default App;